import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { getApiAuthRequest } from 'config/authConfig';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;


export function useAptApi() {
    const { instance, accounts } = useMsal();

    async function apiRequest(method, url, payload = null) {
        payload = payload || null;

        let tokenResponse;
        const authRequest = getApiAuthRequest(accounts[0]);

        try {
            tokenResponse = await instance.acquireTokenSilent(authRequest);
        }
        catch (error) {
            if (error) {
                await instance.acquireTokenRedirect(authRequest);
            }
            else {
                throw (error);
            }
        }

        try {
            const response = await axios.request({
                headers: {
                    Authorization: `Bearer ${tokenResponse.accessToken}`,
                    'Content-Type': 'application/json'
                },
                method: method,
                url: url,
                data: payload,
                validateStatus: function (status) {
                    return status < 500
                }
            });

            return {
                isSuccess: (response.status >= 200 && response.status < 300) ? true : false,
                statusCode: response.request.status,
                message: null,
                data: response.data
            };
        }
        catch (error) {
            return {
                isSuccess: false,
                statusCode: error.request.status,
                message: error.response.data.response.message,
                data: null,
            }
        }
    }

    async function GetEngagements() {
        return await apiRequest('GET', '/engagements');
    }

    async function GetDocuments(engagementId) { 
        return await apiRequest('POST', `/copyDocuments?id=${engagementId}`);
    }

    return { GetEngagements, GetDocuments };
}